import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { delete_cookie } from "sfcookies";

const Logout = (props) => {
  let params = useParams();
  let history = useHistory();
  delete_cookie("pnc-rewards-token");
  delete_cookie("profileId");
  delete_cookie("ProgramCode");
  // sessionStorage.clear();
  // localStorage.clear();
  history.push(`/`);

  return (
    <section className="pnc-help-section">
        <div className="loader"></div>
    </section>
  );
};

export default Logout;
