import React from 'react';
import { useHistory } from "react-router-dom";

const MaintenancePage = (props) => {
    document.title = props.title;
    let history = useHistory();

    return (
        <section className="pnc-contact-us" style={{textAlign:"center"}}>
            <div className="container">
                <div className="row">
                    <div className="offset-md-1 col-md-11">
                        <div className="contact_us">

                            {/* <div className="alert alert-danger" role="alert">
                                 User not found
                            </div> */}

                            <h3 style={{color:"#f58025"}}> This website is under maintenance.</h3>
                            <h5 aria-level="1">Thank You for visiting PNC points</h5>


                            <p>We apologize for the inconvenience this may have caused you.</p>
                            <p>We will be back soon</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MaintenancePage;