import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { delete_cookie } from "sfcookies";
import {bake_cookie} from "../read_cookie"
const Redirect = (props) => {
  let params = useParams();
  let history = useHistory();
  let fullEncStr = params.id;
  delete_cookie("pnc-rewards-token");
  delete_cookie("profileId");
  delete_cookie("ProgramCode");
  // sessionStorage.clear();
  // localStorage.clear();
  if (fullEncStr !== 0) {
    let encArr = fullEncStr.split("&&");
    let token = encArr[0].toString();
    let profileId = encArr[1].toString();
    let ProgramCode = encArr[2].toString();
    bake_cookie("pnc-rewards-token", token);
    bake_cookie("profileId", profileId);
    bake_cookie("ProgramCode", ProgramCode);
  }
  history.push(`/`);

  return (
    <section className="pnc-help-section">
        <div className="loader"></div>
    </section>
  );
};

export default Redirect;
