import { delete_cookie } from "sfcookies";
import { read_cookie } from "../read_cookie";
const Auth = {
    isAuthenticated: false,
    authenticate() {
        try {
            if(read_cookie('token') !== "" && !Array.isArray(read_cookie('token'))){
                this.isAuthenticated = true;
            }else{
                this.isAuthenticated = false; 
            } 
        } catch (error) {
            delete_cookie('token')
            this.isAuthenticated = false; 
        }             
    },
    signout() {
        this.isAuthenticated = false;
    },
    getAuth() {
        return this.isAuthenticated;
    }
};
export default Auth;