import React from "react";
import { StoreProvider } from "easy-peasy";
import Store from "./service/store";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Contact from "./components/contact/contact";
// import Help from "./components/help/help";
// import ForgotPassword from "./components/forgotPassword/forgotPassword";
// import RetriveUsername from "./components/retriveUsername/retriveusername";
import MyProfile from "./components/myProfile/myProfile";
import MyCommunication from "./components/myCommunication/myCommunication";
import MyAccount from "./components/myAccount/myAccount";
import MessageDetails from "./components/messageDetails/messageDetails";
import Transaction from "./components/transaction/transaction";
import OrderHistory from "./components/orderHistory/orderHistory";
import RewardMessages from "./components/rewardMessage/rewardMessages";
import LinkedCards from "./components/linkedCards/linkedCards";
// import forgotData from "./data/forgot-password.json";
// import ProfileID from "./components/home/profieId";
import Resp from "./components/Redirect";
import SAML from "./components/bakktSaml";
import Logout from "./components/logout/logout";
import { createBrowserHistory } from "history";
import Faq from "./components/faq/faq";
import FrontPorch from './components/frontporch';
import Confirmation from './components/confirmation/confirmation';
import Notfound from "./components/notfound";
import Auth from "./components/auth";
import CLickBack from "./components/confirmation/clickback";
import ErrorPage from "./components/errorPage";
import MaintenancePage from "./components/maintenancePage";

const customHistory = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.getAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      )
    }
  />
);

Auth.authenticate();

function App() {
  let catalogUrl;
  if(process.env.REACT_APP_ENV == "production"){
    catalogUrl = process.env.REACT_APP_CATALOG_URL 
  }
  else{
    catalogUrl = process.env.REACT_APP_CATALOG_URL_QA
  }
  global.catalogUrl = catalogUrl;
  return (
    <React.Fragment>
      <StoreProvider store={Store}>
        <Router forceRefresh={true} history={customHistory}>       
          <Header />
          <section className="pnc-wrapper">
          <Switch>
            {process.env.REACT_APP_MAINTANENCE === "true" ? <Route path="*"  component={() => <MaintenancePage title="PNC Points | MaintenancePage"/>} />
                : false}
            <Route exact path="/home" component={() => <Home title="PNC Points | Home" />} />
            <Route
              exact
              path="/"
              
              component={() => <Home title="PNC Points | Home" />}
            />
            <Route
              exact
              path="/contact"
              
              component={() => <Contact title="PNC Points | Contact Us" />}
            />
            <Route
              exact
              path="/Help/ContactUs"
              
              component={() => <Contact title="PNC Points | Contact Us" />}
            />
            {/* <Route
              exact
              path="/help"
              
              component={() => <Help title="PNC Points | Help" />}
            /> */}
            {/* <Route
              exact
              path="/retrive-username"
              
              component={() => (
                <RetriveUsername title="PNC Points | Retrive Username" />
              )}
            /> */}
            <PrivateRoute
              exact
              path="/MyAccount/Communications"
              
              component={() => (
                <MyCommunication title="PNC Points | My Communications" />
              )}
            />
            <PrivateRoute
              exact
              path="/MyAccount"
              
              component={() => <MyAccount title="PNC Points | My Account" />}
            />
            <PrivateRoute
              exact
              path="/messageDetails/:type/:id"
              
              component={() => (
                <MessageDetails title="PNC Points | Message Details" />
              )}
            />
            <PrivateRoute
              exact
              path="/MyAccount/Transactions"
              
              component={() => (
                <Transaction title="PNC Points | Transactions" />
              )}
            />
            <PrivateRoute
              exact
              path="/MyAccount/OrderHistory"
              
              component={() => (
                <OrderHistory title="PNC Points | Order History" />
              )}
            />
            <PrivateRoute
              exact
              path="/MyAccount/Profile"
              
              component={() => <MyProfile title="PNC Points | My Profile" />}
            />
            <PrivateRoute
              exact
              path="/Messaging"
              
              component={() => <RewardMessages title="PNC Points | Messages" />}
            />
            {/* <Route
              exact
              path="/forgotpassword"
              
              component={() => (
                <ForgotPassword
                  title="PNC Points | Forgot Password"
                  forgotData={forgotData}
                />
              )}
            /> */}
            <PrivateRoute
              exact
              path="/MyAccount/LinkedCards"
              
              component={() => (
                <LinkedCards title="PNC Points | Linked Cards" />
              )}
            />
            <Route
              exact
              path="/Help/FAQs"
              
              component={() => <Faq title="PNC Points | FAQ" />}
            />
            <Route exact path="/SAML/b2rLogin"  component={() => <SAML />} />
            <Route exact path="/FrontPorch"  component={() => <FrontPorch />} />
            <Route exact path="/confirmation"  component={() => <Confirmation title="PNC points | Email Address Confirmation"/>} />
            <Route exact path="/clickback/confirmation/"  component={() => <CLickBack title="PNC points | Email Address Confirmation"/>} />
            <Route exact path="/resp/:id"  component={() => <Resp />} />          
            <Route exact path="/Logout/Logout"  component={() => <Logout />} />
            <Route exact path="/errorPage"  component={() => <ErrorPage title="PNC Points | Error"/>} />
            <Route path="*"  component={() => <Notfound title="PNC points | System Error"/>} />
                        
            </Switch>
          </section>
          <Footer />   
        </Router>
      </StoreProvider>
    </React.Fragment>
  );
}

export default App;
